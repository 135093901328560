// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button-st-pending {
    width: 100%!important;
    height: 100% !important;
    color: #0b5ed7 !important;
    background-color: transparent !important;
    border: solid 2px #0b5ed7 !important;
}

.btn-table-st-pending {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px !important;
    padding-top: 2% !important;
    padding-bottom: 2% !important;
}

.selected-button-st-pending {
    width: 100% !important;
    height: 100% !important;
    border: none;
    background-color: #22224f;
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/Buttons.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,uBAAuB;IACvB,yBAAyB;IACzB,wCAAwC;IACxC,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,8BAA8B;IAC9B,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;IACZ,yBAAyB;IACzB,uBAAuB;AAC3B","sourcesContent":[".filter-button-st-pending {\n    width: 100%!important;\n    height: 100% !important;\n    color: #0b5ed7 !important;\n    background-color: transparent !important;\n    border: solid 2px #0b5ed7 !important;\n}\n\n.btn-table-st-pending {\n    width: 100% !important;\n    height: 100% !important;\n    border-radius: 10px !important;\n    padding-top: 2% !important;\n    padding-bottom: 2% !important;\n}\n\n.selected-button-st-pending {\n    width: 100% !important;\n    height: 100% !important;\n    border: none;\n    background-color: #22224f;\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
