// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-st-pending {
  text-align: center;
  display: flex;
  justify-content: center;

  min-height: 100vh;
  background-color: #f0f0f0;
  font-family: "Roboto", sans-serif;
}


.App-table-st-pending {
  text-align: center;
  max-width: 90%;
  height: 100%;
  padding-top: 3%;
  padding-bottom: 3%;
  min-width: 90%;
}

#tableSection-st-pending {
  background-color: white;
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  max-height: 70vh;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;;EAEvB,iBAAiB;EACjB,yBAAyB;EACzB,iCAAiC;AACnC;;;AAGA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,6BAA6B;EAC7B,4BAA4B;EAC5B,gBAAgB;AAClB","sourcesContent":[".App-st-pending {\n  text-align: center;\n  display: flex;\n  justify-content: center;\n\n  min-height: 100vh;\n  background-color: #f0f0f0;\n  font-family: \"Roboto\", sans-serif;\n}\n\n\n.App-table-st-pending {\n  text-align: center;\n  max-width: 90%;\n  height: 100%;\n  padding-top: 3%;\n  padding-bottom: 3%;\n  min-width: 90%;\n}\n\n#tableSection-st-pending {\n  background-color: white;\n  height: 100%;\n  border-top-right-radius: 10px;\n  border-top-left-radius: 10px;\n  max-height: 70vh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
